(function bvid() {
  function validDomain(origin) {
    // Incoming request should only be from box sites.
    const allowedDomains = ['box.com', 'box.org'];
    const domainInstance = new URL(origin);
    const domainOrigin = domainInstance.hostname;
    const splitDomain = domainOrigin.split('.');
    const domain = splitDomain.slice(-2).join('.');

    if (allowedDomains.indexOf(domain) !== -1) {
      return true;
    }
    return false;
  }

  // Create box visitor ID for use in gen204 tracking below.
  // Similar to http://phpjs.org/functions/uniqid/
  function uniqid(prefix, moreEntropy) {
    // +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // +    revised by: Kankrelune (http://www.webfaktory.info/)
    // %        note 1: Uses an internal counter (in php_js global) to avoid collision
    if (typeof prefix === 'undefined') {
      prefix = '';
    }

    let retId;
    let formatSeed = function (seed, reqWidth) {
      // To hex str.
      seed = parseInt(seed, 10).toString(16);

      // So long we split.
      if (reqWidth < seed.length) {
        return seed.slice(seed.length - reqWidth);
      }

      // So short we pad.
      if (reqWidth > seed.length) {
        return Array(1 + (reqWidth - seed.length)).join('0') + seed;
      }
      return seed;
    };

    // BEGIN REDUNDANT

    if (!window.php_js) {
      window.php_js = {};
    }

    // END REDUNDANT
    // Init seed with big random int.
    if (!window.php_js.uniqidSeed) {
      window.php_js.uniqidSeed = Math.floor(Math.random() * 0x75bcd15);
    }
    window.php_js.uniqidSeed += 1;

    // Start with prefix, add current milliseconds hex string.
    retId = prefix;
    retId += formatSeed(parseInt(new Date().getTime() / 1000, 10), 8);

    // Add seed hex string.
    retId += formatSeed(window.php_js.uniqidSeed, 5);
    if (moreEntropy) {
      // For more entropy we add a float lower to 10.
      retId += (Math.random() * 10).toFixed(8).toString();
    }

    return retId;
  }

  // Extract box_visitor_id cookie value.
  function getBvidValue() {
    return document.cookie
      .split('; ')
      .find((row) => row.startsWith('box_visitor_id='))
      ?.split('=')[1];
  }

  // Generate BVID if it doesn't exist yet.
  if (!getBvidValue()) {
    const uniqueId = uniqid('', true);

    const cookieDomain = `.${document.domain.replace(/^[^.]+\./g, "")}`;
    const CookieDate = new Date();
    CookieDate.setFullYear(CookieDate.getFullYear() + 1);
    document.cookie = `box_visitor_id=${uniqueId}; domain=${cookieDomain}; expires=${CookieDate.toGMTString()}; SameSite=None; Secure`;
  }

  // Recieving messages.
  window.addEventListener('message', (event) => {
    // Security check.
    if (!validDomain(event.origin)) {
      return;
    }

    const key = event.message ? 'message' : 'data';
    const data = event[key];

    // Get visitor ID and send it to parent.
    if (data === 'get_bvid') {
      const obj = {
        event: 'bvid_synced',
        uniqueId: getBvidValue(),
      };

      // eslint-disable-next-line no-restricted-globals
      parent.postMessage(obj, '*');
    }
  });
}());

